import React from "react"

import Layout from "../components/layout-static"
import Seo from "../components/seo"


const Page = () => {
  const metadata = {
    title: "Road-related fatalities are still going up",
    heroH1: "Road-related fatalities are still going up",
    heroImage: "blg-van-accident.jpg",
    heroImageAlt: "Photo of an automobile accident involving a van and a bicycle",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <p className="mb-8">
              <strong>September 9, 2022</strong> by Frank Bartlett
            </p>

            <h2>Road-related fatalities are still going up</h2>

            <p className="mb-8">
              Driving here in Connecticut has gotten more dangerous in the last
              few years. This surge comes after decades of declining crash
              numbers and fatalities since the 1960s. While the crash numbers
              remain down, fatalities climbed. According to{" "}
              <a
                href="https://www.ctinsider.com/hartford/article/CT-launches-anti-speeding-campaign-through-Labor-17285442.php"
                target="_blank"
                rel="noreferrer"
              >
                local reports
              </a>
              , there were 166 traffic-related deaths reported in Connecticut in
              the first half of 2022. For the same period of Jan. 1 through June
              19, there were 123 deaths last year and 126 in 2020.
            </p>

            <h2>The pandemic changed driving habits</h2>
            <p className="mb-8">
              The world went into a pandemic lockdown in 2020 where
              non-essential workers lost their jobs or worked remotely.
              Businesses, retailers, restaurants and bars shuttered, so people
              went out less or not at all. The traffic volume was suddenly
              significantly reduced, leaving the roads relatively empty.
            </p>

            <p className="mb-8">
              Perhaps blowing off steam due to pandemic-related stress,
              drivers&#39; speeds increased on the empty roads, with record
              numbers of tickets for driving faster than 100 mph. Reports of
              dangerous speeds on non-interstates were also common, with 87% of
              all speeding-related fatalities in the U.S. taking place on
              surface roads. Fewer drivers driving more dangerously meant
              numbers stayed the same in 2020, and fatality rates went up.
            </p>

            <h2>Agencies step up enforcement</h2>
            <p className="mb-8">
              As traffic volume returns to near pre-pandemic levels, the roads
              are again crowded, but drivers are not slowing down. This
              development and the high fatality numbers prompted
              Connecticut&#39;s Department of Transportation and other state and
              local law enforcement agencies to launch a summer-long initiative
              to step up speed limit enforcement to get drivers to slow down.
            </p>

            <p className="mb-8">
              “When speeding is involved in a fatal crash, it is never an
              accident,” Connecticut DOT Commissioner Joseph Giulietti said.
              “Our goal is to change this deliberate behavior and send a message
              to drivers that they could avoid speeding-related deaths in
              Connecticut if all drivers obeyed the posted speed limits.”
            </p>

            <h2>Families can hold the reckless accountable</h2>
            <p className="mb-8">
              Unfortunately, these strong words and enforcement efforts are not
              enough to save the victims, and road-related fatalities continue
              to climb. Families of loved ones killed by a speeding driver can
              file a wrongful death claim. This civil suit involves compensation
              for loss of future income, related medical expenses, as well as
              pain and suffering and the lost enjoyment of life's activites.
            </p>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Page
